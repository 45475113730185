import React from 'react';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {graphql} from 'gatsby';
import PageHero from '../shared/hero';
import {Container} from '../Grid';
import Layout from '../Layout';
import SEO from '../Seo';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {BLOCKS} from '@contentful/rich-text-types';
import {StyledHeading, Text} from '../Page/home-cleaning/PageDescription';
import {List} from '../Page/office-cleaning/Included';
import ContactFormPage from '../Page/ContactFormPage';
import {FaqFormWrapper} from '../../pages/window-washing';

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <StyledHeading>{children}</StyledHeading>
    ),
    [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
};

const defaultDocument = {
  nodeType: 'document',
  data: {},
  content: [],
};

const JobPost = ({data, pageContext}) => {
  const {language} = useI18next();
  const {background, backgroundMobile, backgroundTablet} = data;
  console.log(pageContext);
  const content = JSON.parse(
    pageContext?.content
      ? pageContext.content.raw
      : JSON.stringify(defaultDocument),
  );
  return (
    <Layout headerStyle="homeCleaning">
      <SEO
        title={pageContext.seo.title}
        description={pageContext.seo.description}
        lang={language}
      />
      <PageHero
        content={{
          heading: pageContext.headline,
        }}
        imgObj={{
          background,
          backgroundMobile: backgroundMobile,
          backgroundTablet: backgroundTablet,
        }}
      />
      <div style={{marginTop: 120}} />
      <Container>{documentToReactComponents(content, options)}</Container>
      <div style={{ marginTop: 50 }}/>
      <Container>
        <FaqFormWrapper>
          <ContactFormPage />
        </FaqFormWrapper>
      </Container>
    </Layout>
  );
};

export default JobPost;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {
          in: [
            "common"
            "cleaning_service"
            "seo_cleaning_services_prague"
            "homepage"
          ]
        }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    background: file(relativePath: {eq: "career-hero.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundTablet: file(relativePath: {eq: "career-hero-tablet.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundMobile: file(relativePath: {eq: "career-hero-mobile.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
  }
`;
